<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Emails</h3>

			<div class="d-inline-flex align-center">
				<div v-if="search['show']" class="d-inline-block mr-3">
					<v-text-field class="fsk-v-input" v-model="search['input']" name="search" type="search" dense hide-details="auto" prepend-inner-icon="mdi-magnify" outlined clearable></v-text-field>
				</div>
				<v-btn v-if="!search['show']" icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="search['show'] = true"><v-icon>mdi-magnify</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click.stop="showForm"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="resetTable()"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-card :loading="loading" v-if="!reset">
				<v-card-text>
					<v-data-table
						class="fsk-v-data-table"
						:headers="headers"
						:items="table"
						:search="search['input']"
					>
						<template v-slot:[`item.name`]="{ item }">
							<div class="py-3">
								<div>{{ item['name'] }}</div>
								<div class="caption">{{ item['company'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.email`]="{ item }">
							<div>
								<div>{{ item['email'] }}</div>
								<div class="caption">{{ item['phone'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.subject`]="{ item }">
							<div class="v-data-table-text-truncate">{{ item['subject'] }}</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>
								<div>{{ $moment.utc(item['date']).format('DD MMM YYYY') }}</div>
								<div class="caption">{{ formatTime(item['time']) }}</div>
							</div>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div class="text-center">
								<v-chip v-if="item['status']" :color="getStatusColor(item.status)" x-small outlined>{{ item['status'] }}</v-chip>
							</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<div class="v-data-table-actions" :class="{ 'v-data-table-actions--disabled' : disableAction(item.status) }">
								<v-menu bottom left>
									<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
									</template>

									<v-list>
									<v-list-item dense @click="editItem(item)">
										<v-list-item-title>View / Edit</v-list-item-title>
									</v-list-item>
									<v-list-item dense @click="deleteItem(item)">
										<v-list-item-title>Delete</v-list-item-title>
									</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</div>

		<!-- Create Dialog -->
		<v-dialog
			v-model="form['show']"
			persistent
			scrollable
			content-class="fsk-v-create-dialog"
		>
			<v-card :loading="form['loading']" :disabled="form['loading']">
				<v-card-title>
					<span v-if="form['create']" class="headline">New Email</span>
					<span v-else class="headline">Edit Email</span>
				</v-card-title>
				<v-card-text style="max-height: 80vh;">
					<v-row>
						<v-col cols="12" md="6">
							<v-row>
								<v-col cols="6" class="py-0">
									<v-dialog ref="dialogDate" v-model="modal['date']" :return-value.sync="form['data']['date']" persistent width="290px">
										<template v-slot:activator="{ on, attrs }">
										<v-text-field :value="$moment.utc(form['data']['date']).format('DD MMM YYYY')" class="fsk-v-input" label="Date *" name="date" readonly outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-date-picker v-model="form['data']['date']" scrollable @input="$refs.dialogDate.save(form['data']['date'])">
										</v-date-picker>
									</v-dialog>
								</v-col>
								<v-col cols="6" class="py-0">
									<v-dialog ref="dialogTime" v-model="modal['time']" :return-value.sync="form['data']['time']" persistent width="290px">
										<template v-slot:activator="{ on, attrs }">
										<v-text-field :value="formatTime(form['data']['time'])" class="fsk-v-input" label="Time *" name="time" readonly outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-time-picker v-if="modal['time']" v-model="form['data']['time']" full-width  @click:minute="$refs.dialogTime.save(form['data']['time'])">
										</v-time-picker>
									</v-dialog>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="6"></v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['from']" class="fsk-v-input" label="From *" name="from" type="text" outlined dense hide-details="auto" readonly></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['to']" class="fsk-v-input" :items="['Charles Cooper - Hangar Pvt. Ltd', 'Stephen Clark - Smart Ltd', 'Russell Lee - Rus Corp', 'Rachel Wood - CC Corp.', 'Joel Hill - WebTech', 'Kevin Harris - Shazar Tech']" label="To *" name="to" outlined dense hide-details="auto" :multiple="form['create']"></v-select>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="form['data']['subject']" class="fsk-v-input" label="Subject *" name="subject" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="form['data']['template']" class="fsk-v-input" :items="['Birthday Wishes', 'Product Showcase', 'Payment Reminder', '1st Followup', '2nd Followup', '3rd Followup']" label="Template" name="template" clearable outlined dense hide-details="auto"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-file-input v-model="form['data']['attachment']" class="fsk-v-input" label="Attachment" name="attachment" outlined dense hide-details="auto"></v-file-input>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="form['data']['body']" class="fsk-v-input" label="Body *" name="body" rows="5" outlined dense hide-details="auto" no-resize></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4 px-6">
					<v-spacer></v-spacer>
					<v-btn color="grey" text @click="hideForm()">Close</v-btn>
					<v-btn color="primary" text @click="submitForm()">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Delete Dialog -->
		<v-dialog
			v-model="del['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="del['loading']" :disabled="del['loading']">
			<v-card-title class="headline">Delete Email</v-card-title>

			<v-card-text>Please confirm the deletion of email to <strong>{{ del['name'] }}</strong></v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideDelete()">Close</v-btn>
				<v-btn color="primary" text @click="confirmDelete()">Confirm</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data: () => ({
			modal: {
				date: false,
				time: false
			},
			search: {
				show: false,
				input: ''
			},
			loading: false,
			reset: false,
			headers: [
				{
					text: 'Name | Company',
					align: 'start',
					value: 'name',
				},
				{
					text: 'Contact',
					value: 'email',
				},
				{
					text: 'Subject',
					value: 'subject',
				},
				{
					text: 'Date',
					value: 'date',
				},
				{
					text: 'Status',
					value: 'status',
					align: 'center',
				},
				{ text: 'Actions', align: 'end', value: 'actions', sortable: false },
			],
			table: [],
			form: {
				create: true,
				show: false,
				loading: false,
				data: {
					id: '',
					date: '',
					from: '',
					to: '',
					time: '',
					subject: '',
					template: '',
					body: '',
					attachment: null,
					name: '',
					status: '',
				}
			},
			del: {
				show: false,
				loading: false,
				id: '',
				name: ''
			}
		}),

		created() {
			this.initialize();
		},

		methods: {
			initialize () {
				this.table = [
					{
						id: 1,
						name: 'Kevin Harris',
						company: 'Shazar Tech',
						phone: '9231457895',
						email: 'kevin@shazar.com',
						subject: 'Product Showcase Deck',
						body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum iusto veniam, facere at libero a. Dolores reprehenderit possimus aspernatur culpa consequatur optio nulla error, cum odio sequi repellendus quaerat obcaecati.',
						status: 'Sent',
						date: new Date(),
						time: '18:00'
					},
					{
						id: 2,
						name: 'Rachel Wood',
						company: 'CC Corp.',
						phone: '9212678992',
						email: 'rachel@ccc.com',
						subject: 'Followup for CRM Software',
						body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum iusto veniam, facere at libero a. Dolores reprehenderit possimus aspernatur culpa consequatur optio nulla error, cum odio sequi repellendus quaerat obcaecati.',
						status: 'Failed',
						date: new Date(Date.now() - 86400000),
						time: '13:00'
					},
					{
						id: 3,
						name: 'Russell Lee',
						company: 'Rus Corp',
						phone: '9231451423',
						email: 'russell@rcorp.com',
						subject: 'Payment Reminder for Website Development',
						body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum iusto veniam, facere at libero a. Dolores reprehenderit possimus aspernatur culpa consequatur optio nulla error, cum odio sequi repellendus quaerat obcaecati.',
						status: 'Read',
						date: new Date(Date.now() - 3888000000),
						time: '09:30'
					},
					{
						id: 4,
						name: 'Stephen Clark',
						company: 'Smart Ltd',
						phone: '9231457895',
						email: 'stephen@smart.com',
						subject: 'Happy Birthday',
						body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum iusto veniam, facere at libero a. Dolores reprehenderit possimus aspernatur culpa consequatur optio nulla error, cum odio sequi repellendus quaerat obcaecati.',
						status: 'Scheduled',
						date: new Date(Date.now() + 1728000000),
						time: '10:30'
					},
					{
						id: 5,
						name: 'Joel Hill',
						company: 'WebTech',
						phone: '9212678992',
						email: 'joelbhill@rhyta.com',
						subject: 'Followup for CRM Software',
						body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum iusto veniam, facere at libero a. Dolores reprehenderit possimus aspernatur culpa consequatur optio nulla error, cum odio sequi repellendus quaerat obcaecati.',
						status: 'Scheduled',
						date: new Date(Date.now() + 3024000000),
						time: '17:00'
					},
				]
			},
			resetTable() {
				this.loading = true;

				setTimeout(() => {
					this.loading = false;
					this.reset = true;
					this.reset = false;
				}, 3000);
			},
			getStatusColor (status) {
				switch (status) {					
					case 'Scheduled':
						return 'orange'
					
					case 'Sent':
						return 'success'
					
					case 'Read':
						return 'blue'
					
					case 'Failed':
						return 'red'
			
					default:
						return 'grey'
				}
			},
			disableAction(status) {
				if (['Sent', 'Read', 'Failed'].includes(status)) {
					return true
				}
				return false
			},
			editItem(item) {
				// Assign form data
				this.form['data']['id'] = item['id'];
				this.form['data']['date'] = item['date'];
				this.form['data']['time'] = item['time'];
				this.form['data']['to'] = `${item['name']} - ${item['company']}`;
				this.form['data']['subject'] = item['subject'];
				this.form['data']['body'] = item['body'];
				this.form['data']['name'] = item['name'];
				this.form['data']['status'] = item['status'];

				// Show dialog
				this.showForm('edit');
			},
			deleteItem(item) {
				// Assign data
				this.del['id'] = item['id'];
				this.del['name'] = item['name'];

				// Show dialog
				this.del['show'] = true;
			},
			hideDelete() {
				// Reset data
				this.del['id'] = '';
				this.del['name'] = '';

				// Unset loading
				this.del['loading'] = false;

				// Hide dialog
				this.del['show'] = false;
			},
			confirmDelete() {
				// TODO: Add delete email API
				console.log(this.del['id']);

				// Set loading
				this.del['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideDelete();
				}, 3000);
			},
			resetForm() {
				this.form['data']['id'] = '';
				this.form['data']['date'] = this.$moment.utc(new Date()).format('YYYY-MM-DD');
				this.form['data']['from'] = 'rachel@fadesk.com';
				this.form['data']['to'] = '';
				this.form['data']['time'] = '';
				this.form['data']['subject'] = '';
				this.form['data']['template'] = '';
				this.form['data']['body'] = '';
				this.form['data']['attachment'] = null;
				this.form['data']['name'] = '';
				this.form['data']['status'] = '';
			},
			showForm(type = 'create') {
				if (type == 'edit') {
					this.form['create'] = false;
				}
				else {
					this.form['create'] = true;

					// Prepopulate data
					this.form['data']['date'] = this.$moment.utc(new Date()).format('YYYY-MM-DD');
				}

				// Prepopulate data
				this.form['data']['from'] = 'rachel@fadesk.com';

				// Show Dialog
				this.form['show'] = true;
			},
			hideForm() {
				// Hide dialog
				this.form['show'] = false;

				// Unset loading
				this.form['loading'] = false;

				// Clear form
				this.resetForm();
			},
			submitForm() {
				if (this.form['create']) {
					// TODO: Add create email API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				else {
					// TODO: Add edit email API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				setTimeout(() => {
					// Hide dialog
					this.hideForm();
				}, 3000);
			}
		}
	}
</script>
